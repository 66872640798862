import keys from "./pack.keys";

export const InitialState = {
  payload: false,
  packs: [],
};

export const PackReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.set_packs:
      return { ...state, packs: action.value.data, payload: false };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};
